import { NumberInput, NumberInputProps } from '@mantine/core';

function DecimalInput(props: NumberInputProps) {
  return (
    <NumberInput
      {...props}
      pattern="[0-9]*"
      // @ts-expect-error this works, even though the types don't match
      inputmode="decimal"
    />
  );
}

export { DecimalInput };
