import { useQuery } from '@tanstack/react-query';
import { Product } from '../constants/constants';
import { env } from '../env';

const productsQueryKey = 'products';

function useProducts() {
  return useQuery({
    queryKey: [productsQueryKey],
    queryFn: async (): Promise<Product[]> => {
      const res = await fetch(env.VITE_PRODUCTS_URL);
      if (!res.ok) {
        throw new Error('network error');
      }
      return res.json();
    },
    staleTime: Infinity,
  });
}

export { useProducts, productsQueryKey };
