import { NumberFormatter, Table } from '@mantine/core';
import { Conversion } from '../../constants/constants';

function Recipe(props: { conversions: Array<Conversion> }) {
  const { conversions } = props;

  const rows = conversions.map((c) => (
    <Table.Tr key={c.id}>
      <Table.Td ta="right">
        <NumberFormatter value={c.grams} thousandSeparator />
      </Table.Td>
      <Table.Td>{c.product?.name}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Table maw="400px" fz="md">
      <Table.Thead>
        <Table.Tr>
          <Table.Th ta="right">Grams</Table.Th>
          <Table.Th>Name</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
}

export { Recipe };
