import { FormEvent, useState } from 'react';
import { ActionIcon, Box, Button, Group, Stack, TextInput, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { useLocalStorage } from '@mantine/hooks';
import { IconRecycle } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { AuthBar } from '../../components/AuthBar/AuthBar.tsx';
import { routes } from '../../routing/routes.ts';
import { productsQueryKey, useProducts } from '../../hooks/useProducts.tsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
// import { Product } from '../../constants/constants';
import classes from './Admin.module.css';
import { DecimalInput } from '../../components/DecimalInput/DecimalInput.tsx';
import { Product } from '../../constants/constants';
import { env } from '../../env.ts';

// const fieldName = (name: keyof Product) => name;

function RowEditor({ product }: { product: Product }) {
  const [name, setName] = useState(product.name);
  const [grams, setGrams] = useState(product.grams);

  const hasNameChange = name !== product.name;
  const hasGramsChange = grams !== product.grams;
  const hasAnyChanges = hasNameChange || hasGramsChange;

  const reset = () => {
    setName(product.name);
    setGrams(product.grams);
  };

  return (
    <tr>
      <th scope="row" className={classes.right}>
        <input name="products" defaultValue={product.id} hidden />
        {product.id}
      </th>
      <td>
        <TextInput name="products" value={name} onChange={(e) => setName(e.target.value)} />
      </td>
      <td>
        <DecimalInput name="products" value={grams} onChange={(e) => setGrams(Number(e))} />
      </td>
      <td className={classes.controls}>
        {hasAnyChanges && (
          <>
            <ActionIcon onClick={reset}>
              <IconRecycle />
            </ActionIcon>
            <span>
              {hasNameChange && product.name}
              {hasNameChange && hasGramsChange && ': '}
              {hasGramsChange && product.grams}
            </span>
          </>
        )}
      </td>
    </tr>
  );
}

function Admin() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken] = useLocalStorage<string>({
    key: 'accessToken',
    defaultValue: undefined,
  });
  const { data } = useProducts();

  // const [rowData] = useState(data || []);
  // const [colDefs] = useState([
  //   { field: fieldName('id') },
  //   { field: fieldName('name') },
  //   { field: fieldName('grams') },
  // ]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // todo: don't
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (!confirm('update products?')) return;

    const inputs = (e.target as HTMLFormElement).querySelectorAll(
      'input[name="products"]',
    ) as unknown as HTMLInputElement[];

    const products: Product[] = [];

    for (let i = 0; i < inputs.length; i += 3) {
      const product = {
        id: Number(inputs[i].value),
        name: inputs[i + 1].value,
        grams: Number(inputs[i + 2].value),
      };
      products.push(product);
    }

    if (accessToken) {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      headers.append('Content-Type', 'application/json');

      const requestOptions = {
        method: 'PATCH',
        headers,
        body: JSON.stringify(products),
      };

      try {
        const res = await fetch(`${env.VITE_API_URL}/api/products`, requestOptions);

        if (!res.ok) {
          throw new Error(`error patching products: ${JSON.stringify(res)}`);
        }

        queryClient.invalidateQueries({ queryKey: [productsQueryKey] });
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    }
  };

  return (
    <>
      <Group>
        <Button onClick={() => navigate(routes.home)}>home</Button>
        <AuthBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </Group>
      <Stack p={{ base: '24px 12px', md: '24px 12px' }}>
        <Title order={1}>admin</Title>
        {/*todo: pending react 19 support from ag grid*/}
        {/*<div className="ag-theme-quartz">*/}
        {/*<AgGridReact rowData={rowData} columnDefs={colDefs} />*/}
        {/*</div>*/}
        {isLoggedIn && (
          <Box>
            <form onSubmit={onSubmit}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">name</th>
                    <th scope="col">grams</th>
                    <th scope="col">controls</th>
                  </tr>
                </thead>
                <tbody>{!!data && data.map((d) => <RowEditor key={d.id} product={d} />)}</tbody>
              </table>
              <Button type="submit">Submit</Button>
            </form>
          </Box>
        )}
        {!isLoggedIn && <p>user is not in the sudoers file. this incident will be reported.</p>}
      </Stack>
    </>
  );
}

export { Admin };
