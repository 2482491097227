import { Button, Flex, Stack, Title } from '@mantine/core';
import { useState } from 'react';
import { Conversion } from '../../constants/constants';
import { Converter } from '../../components/Converter/Converter.tsx';
import { convertToGrams } from '../../components/Converter/Converter.functions.ts';
import { Recipe } from '../../components/Recipe/Recipe.tsx';

const emptyConversion: Conversion = {
  id: 0,
  amount: 0,
  measurement: null,
  product: null,
  grams: 0,
};

function Home() {
  const [isEditMode, setIsEditMode] = useState(true);
  const [conversions, setConversions] = useState<Array<Conversion>>([
    {
      ...emptyConversion,
      id: 1,
    },
  ]);

  const getNextId = () => (conversions?.length ? conversions[conversions.length - 1].id + 1 : 0);

  const addConversion = () => {
    setConversions((prev) => [...prev, { ...emptyConversion, id: getNextId() }]);
  };

  const updateConversion = (conversion: Conversion) => {
    conversion.grams =
      conversion.amount && conversion.measurement && conversion.product
        ? convertToGrams(conversion.amount, conversion.measurement, conversion.product)
        : 0;

    setConversions((prev) => prev.map((c) => (c.id === conversion.id ? { ...conversion } : c)));
  };

  const removeConversion = (conversion: Conversion) => {
    setConversions((prev) => prev.filter((c) => c.id !== conversion.id));
  };

  return (
    <Stack p={{ base: '24px 12px', md: '24px 12px' }}>
      <Title order={1} m={{ base: '0 auto', md: 'unset' }}>
        GramIt!
      </Title>
      {isEditMode ? (
        conversions.map((c) => (
          <Converter
            key={c.id}
            conversion={c}
            updateConversion={updateConversion}
            removeConversion={removeConversion}
          />
        ))
      ) : (
        <Recipe conversions={conversions} />
      )}
      <Flex direction={{ base: 'row' }} gap={{ base: '8px', md: '12px' }}>
        {isEditMode ? (
          <>
            <Button size="md" w={{ base: '50%', md: 'unset' }} onClick={addConversion}>
              +
            </Button>
            <Button size="md" w={{ base: '50%', md: 'unset' }} onClick={() => setIsEditMode(false)}>
              recipe
            </Button>
          </>
        ) : (
          <Button size="md" w={{ base: '100%', md: 'unset' }} onClick={() => setIsEditMode(true)}>
            edit
          </Button>
        )}
      </Flex>
    </Stack>
  );
}

export { Home };
