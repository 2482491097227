import '@mantine/core/styles.css';
import { Box, MantineProvider } from '@mantine/core';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Capacitor } from '@capacitor/core';
import { SchemeChooser } from './components/SchemeChooser/SchemeChooser.tsx';
import { router } from './routing/router.tsx';
import { theme } from './theme.ts';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <MantineProvider theme={theme}>
        <SchemeChooser />
        <Box
          pt={Capacitor.getPlatform() === 'ios' ? '32px' : undefined}
          pb={Capacitor.getPlatform() === 'ios' ? '14px' : undefined}
        >
          <RouterProvider router={router} />
        </Box>
      </MantineProvider>
    </QueryClientProvider>
  );
}

export { App };
