import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Home } from '../pages/Home/Home.tsx';
import { routes } from './routes.ts';
import { Admin } from '../pages/Admin/Admin.tsx';

const router = createBrowserRouter([
  { path: routes.home, element: <Home /> },
  { path: routes.admin, element: <Admin /> },
  { path: '*', element: <Navigate to="/" /> },
]);

export { router };
