import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Button } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { jwtDecode } from 'jwt-decode';
import { env } from '../../env.ts';

const authUrl = env.VITE_COGNITO_AUTH_URL;
const clientId = env.VITE_COGNITO_CLIENT_ID;
const scope = env.VITE_COGNITO_SCOPE;
const redirectUri = `${window.location.origin}/admin`;
const codeResponseType = 'code';

const hostedUiUrl = `${authUrl}/login?client_id=${clientId}&response_type=${codeResponseType}&scope=${scope}&redirect_uri=${redirectUri}`;

function AuthBar({
  isLoggedIn,
  setIsLoggedIn,
}: {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}) {
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage<string>({
    key: 'accessToken',
    defaultValue: undefined,
  });
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage<string>({
    key: 'refreshToken',
    defaultValue: undefined,
  });
  const [, setTokenExpiration, removeTokenExpiration] = useLocalStorage<number>({
    key: 'tokenExpiration',
    defaultValue: undefined,
  });

  const handleLogin = () => {
    window.location.href = hostedUiUrl;
  };

  const handleLogout = () => {
    removeAccessToken();
    removeRefreshToken();
    removeTokenExpiration();
    setIsLoggedIn(false);
  };

  const getTokens = useCallback(
    async (code: string): Promise<void> => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');

      const urlEncoded = new URLSearchParams();
      urlEncoded.append('grant_type', 'authorization_code');
      urlEncoded.append('client_id', clientId);
      urlEncoded.append('code', code);
      urlEncoded.append('redirect_uri', redirectUri);

      const requestOptions = {
        method: 'POST',
        headers,
        body: urlEncoded,
        redirect: 'follow',
      };

      try {
        // @ts-expect-error todo: figure this out later
        const res = await fetch(`${authUrl}/oauth2/token`, requestOptions);
        const tokenRes = await res.json();

        if (tokenRes.error) throw new Error(tokenRes.error);

        setAccessToken(tokenRes.access_token);
        setRefreshToken(tokenRes.refresh_token);

        const decoded = jwtDecode(tokenRes.access_token);
        setTokenExpiration((decoded.exp as number) * 1000);
      } catch (e) {
        // eslint-disable-next-line
        console.log('failed auth', e);
      }
    },
    [setAccessToken, setRefreshToken, setTokenExpiration],
  );

  useEffect(() => {
    let { search } = window.location;
    if (!search) return;

    if (search[0] === '?') {
      search = search.slice(1);
    }

    const parts = search.split('&');

    const codeParam = parts.find((p) => p.startsWith('code='));

    if (!codeParam) return;

    window.history.replaceState(null, '', window.location.pathname);
    const code = codeParam.split('=')[1];

    if (code) {
      getTokens(code);
    }
  }, [getTokens]);

  useEffect(() => {
    if (accessToken && refreshToken) {
      setIsLoggedIn(true);
    }
  }, [accessToken, refreshToken, setIsLoggedIn]);

  return (
    <span>
      <Button onClick={isLoggedIn ? handleLogout : handleLogin}>
        {isLoggedIn ? 'log out' : 'log in'}
      </Button>
    </span>
  );
}

export { AuthBar };
