import { ActionIcon, useComputedColorScheme, useMantineContext } from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';
import clsx from 'clsx';
import { useColorScheme } from '@mantine/hooks';
import classes from './SchemeChooser.module.css';

function SchemeChooser() {
  const colorScheme = useColorScheme();
  const { setColorScheme } = useMantineContext();
  const computedColorScheme = useComputedColorScheme(colorScheme, {
    getInitialValueInEffect: true,
  });

  return (
    <ActionIcon
      onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
      variant="default"
      size="xs"
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
      }}
    >
      <IconSun className={clsx(classes.icon, classes.light)} stroke={1} />
      <IconMoon className={clsx(classes.icon, classes.dark)} stroke={1} />
    </ActionIcon>
  );
}

export { SchemeChooser };
