import {
  createTheme,
  defaultVariantColorsResolver,
  MantineColorsTuple,
  rem,
  VariantColorsResolver,
  virtualColor,
} from '@mantine/core';

const green: MantineColorsTuple = [
  '#e5ffe5',
  '#cdffce',
  '#9bff9e',
  '#64ff69',
  '#39ff3e',
  '#1eff22',
  '#09ff11',
  '#00e301',
  '#00c900',
  '#00ae00',
];

const violet: MantineColorsTuple = [
  '#f6ecff',
  '#e7d6fb',
  '#caabf1',
  '#ac7ce8',
  '#9354e0',
  '#833cdb',
  '#7b2eda',
  '#6921c2',
  '#5d1cae',
  '#501599',
];

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  if (input.variant === 'danger') {
    return {
      background: 'var(--mantine-color-red-9)',
      hover: 'var(--mantine-color-red-8)',
      color: 'var(--mantine-color-white)',
      border: 'none',
    };
  }

  return defaultResolvedColors;
};

const theme = createTheme({
  scale: 1,
  respectReducedMotion: true,
  fontSmoothing: true,
  fontFamily: 'sans-serif',
  fontFamilyMonospace: 'monospace',
  headings: {
    fontFamily: 'sans-serif',
    sizes: {
      h1: {
        fontWeight: '600',
        fontSize: rem(36),
        lineHeight: '1.4',
      },
    },
  },
  colors: {
    green,
    violet,
    primary: virtualColor({
      name: 'primary',
      dark: 'green',
      light: 'violet',
    }),
  },
  variantColorResolver,
  primaryColor: 'primary',
  primaryShade: 7,
  autoContrast: true,
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '88em',
  },
});

export { theme };
