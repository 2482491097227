import {
  Autocomplete,
  Button,
  Center,
  Flex,
  NativeSelect,
  NumberFormatter,
  Text,
} from '@mantine/core';
import { Conversion } from '../../constants/constants';
import measurements from '../../constants/measurements.json';
import { useProducts } from '../../hooks/useProducts.tsx';
import { DecimalInput } from '../DecimalInput/DecimalInput.tsx';

function Converter(props: {
  conversion: Conversion;
  updateConversion: (conversion: Conversion) => void;
  removeConversion: (conversion: Conversion) => void;
}) {
  const { conversion, updateConversion, removeConversion } = props;

  const { isPending, error, data } = useProducts();

  const updateProduct = (v: string) => {
    const product = data?.find((p) => p.name === v);
    product && updateConversion({ ...conversion, product });
  };

  const updateAmount = (amount: string | number) => {
    const a = Number(amount);

    updateConversion({
      ...conversion,
      amount: Number.isNaN(a) ? 0 : a,
    });
  };

  const updateMeasurement = (v: string) => {
    const measurement = measurements.find((m) => m.id === Number(v));
    measurement && updateConversion({ ...conversion, measurement });
  };

  return (
    <Flex direction={{ base: 'column-reverse', md: 'row' }} gap={{ base: '8px', md: '12px' }}>
      <Button size="md" variant="danger" onClick={() => removeConversion(conversion)}>
        x
      </Button>
      <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '8px', md: '12px' }}>
        <Autocomplete
          size="md"
          placeholder="search products"
          aria-label="search products"
          disabled={isPending || !!error}
          data={isPending || error ? [] : data.map((d) => d.name)}
          defaultValue={conversion.product?.name || ''}
          onOptionSubmit={updateProduct}
        />
        <DecimalInput
          size="md"
          value={conversion.amount === 0 ? '' : `${conversion.amount}`}
          onChange={updateAmount}
          placeholder="enter amount"
          hideControls
        />
        <NativeSelect
          size="md"
          value={conversion.measurement ? `${conversion.measurement.id}` : ''}
          onChange={(e) => updateMeasurement(e.target.value)}
          data={[
            { label: 'select measurement', value: '', disabled: true },
            ...measurements.map((m) => ({
              label: m.name,
              value: `${m.id}`,
            })),
          ]}
        />
        <Center>
          <Text data-testid="here">
            <NumberFormatter value={conversion.grams} thousandSeparator /> grams
          </Text>
        </Center>
      </Flex>
    </Flex>
  );
}

export { Converter };
