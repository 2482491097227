import { Measurement, Product } from '../../constants/constants';

function convertToGrams(amount: number, measurement: Measurement, product: Product) {
  if (measurement.type === 'volume') {
    return Math.floor((amount / measurement.ratio) * product.grams);
  }

  if (measurement.type === 'weight') {
    return Math.floor(amount * measurement.ratio);
  }

  return 0;
}

export { convertToGrams };
